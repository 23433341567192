import React, { Component } from "react";
import { connect } from "react-redux";
import { configureHeader } from "@actions/ui";
import Footer from "@components/global/footer/footer";

class ApplyToSubmit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      invalid: false,
      error: "",
      emailClient: true,
    };
  }

  componentDidMount = () => {
    if (window) {
      window.scrollTo(0, 0);
    }
    this.props.configureHeader({
      headerVisibilityLocked: true,
      headerTheme: "white",
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.emailClient && !this.state.emailClient) {
      this.emailBox.select();
    }
  };

  onContactClick = () => {
    setTimeout(() => {
      this.setState(
        Object.assign({}, this.state, {
          emailClient: false,
        })
      );
    }, 200);
  };

  render() {
    return (
      <div className="application-container">
        <div className="container__application">
          <div className="container__application__content">
            <div className="row">
              <div className="col s12 col--no-margin-bottom">
                <div
                  className="heading heading--primary"
                  style={{ maxWidth: "400px" }}
                >
                  <h1 className="heading__headline">Submitting Events</h1>
                  <span className="heading__divider"></span>
                  <p className="heading__copy">
                    {
                      "Restless Nites is a network of curators, promoters, and venues. We are determined to be selective in what we offer as a clearinghouse of the best events in each city."
                    }
                    <br />
                    <br />
                    {
                      "To submit events, please first get in touch here, and let us know what you've got coming up. We look forward to boosting the signal on your happenings."
                    }
                  </p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col s12 m4 offset-m4 col--no-margin-bottom col--button">
                {this.state.emailClient ? (
                  <a href="mailto:info@restlessnites.com?subject=Event%20Submission">
                    <button
                      className="btn"
                      onClick={() => this.onContactClick()}
                    >
                      {"CONTACT US"}
                    </button>
                  </a>
                ) : (
                  <input
                    onClick={() => {
                      this.emailBox.select();
                    }}
                    ref={(ref) => {
                      this.emailBox = ref;
                    }}
                    className="email-box"
                    defaultValue={"info@restlessnites.com"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    configureHeader: (val) => {
      dispatch(configureHeader(val));
    },
  };
};

const ApplyRedux = connect(null, mapDispatchToProps)(ApplyToSubmit);

export default ApplyRedux;
